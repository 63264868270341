@media only screen and (max-width: 500px) {
  .notfound .notfound-404 h1 {
    font-size: 156px;
    top: 10%;  
  }
  .notfound h2 {
    font-size: 30px;
  }
  .notfound p {
    font-size: 12px;
  }  
  .notfound a {
    font-size: 10px;
    padding: 10px 38px;
  }

  .login-card {
    min-width: 0;
    width: 100%;
  }
  .login-title {
    padding-left: 10px;
    padding-right: 10px;
  }  

  .navbar-light .navbar-toggler {
    border-color: rgba(245, 245, 245, 0.5)
  }

  .navbar-brand img {
    height: 32px !important;
  }
  .notification {
    display: none;
  }  
  .notification-sm {
    display: block;
  }  
  .profile-web {
    display: none;
  }
  .nav-menu {
    display: block;
  }

  .dropdown-item {
    color: rgba(245, 245, 245);
  }

  .breadcrumb {
    display: none;
  }

  .bulk-div {
    text-align: center;
    margin-bottom: 10px;
  }
  .search-div {
    text-align: center;
  }
  
  
}